// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  background: rgba(0, 0, 0, 0.2);
  padding: 45px 30px 30px 30px;
}
.container .midContainer {
  height: 400px;
  background-color: white;
  display: flex;
  overflow: auto;
  width: 800px;
  padding: 40px;
}
.container .chooseText {
  text-align: center;
  width: auto;
  padding-bottom: 40px;
}
.container .chooseText .text {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/content/content.scss"],"names":[],"mappings":"AACA;EACI,8BAAA;EACC,4BAAA;AAAL;AACA;EACI,aAAA;EACA,uBAAA;EACD,aAAA;EACA,cAAA;EACE,YAAA;EACA,aAAA;AACL;AAEA;EACI,kBAAA;EACA,WAAA;EACA,oBAAA;AAAJ;AACI;EACI,eAAA;AACR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
