import { useDraggable } from "@dnd-kit/core";
import React from "react";
import { Button, ThemeIcon } from "@mantine/core";
import { IDragableButton } from "./toolbar.model";

const DragableButton: React.FC<IDragableButton> = ({ id, title, Icon }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id, data: {title, Icon, id}
  });
  return (
    <Button
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      leftSection={
        <ThemeIcon variant="white" color="rgba(67, 174, 216)">
          <Icon />
        </ThemeIcon>
      }
      variant="outline"
      color="dark"
    >
      {title}
    </Button>
  );
};

export default DragableButton;
