import {
    IconChartPie,
    IconCircleArrowDownFilled,
    IconCircleCheck,
    IconCircleHalf2,
    IconDice6Filled,
    IconGitMerge,
    IconHeading,
    IconLetterA,
    IconMedal,
    IconMessage,
    IconThumbUp,
    IconTrendingDown,
  } from "@tabler/icons-react";
import { IDragableButton } from "./toolbar.model";

const ToolBarData:IDragableButton[][] = [
    [
      {id:1, title: "Multiple Choice", Icon: IconCircleCheck },
      {id:2, title: "Multi Choice Grid", Icon: IconCircleCheck },
      {id:3, title: "Drop Down", Icon: IconCircleArrowDownFilled },
      {id:4, title: "Drop Down Grid", Icon: IconCircleArrowDownFilled },
      {id:5, title: "Yes / No", Icon: IconCircleHalf2 },
      {id:6, title: "Net Promtor", Icon: IconThumbUp },
    ],
    [
      {id:7, title: "Text Field", Icon: IconMessage },
      {id:8, title: "Text Field Grid", Icon: IconMessage },
    ],
    [
      {id:9,title: "Rating Scale", Icon: IconTrendingDown },
      {id:10, title: "Rating Matrix", Icon: IconDice6Filled },
      {id:11, title: "Ranking", Icon: IconMedal },
      {id:13, title: "Percentage/Sum", Icon: IconChartPie },
    ],
    [
      {id:14, title: "Heading", Icon: IconHeading },
      {id:15, title: "Custom Text", Icon: IconLetterA },
      {id:16, title: "Score Display", Icon: IconMedal },
      {id:17, title: "Page Break", Icon: IconGitMerge },
    ],
  ];

export default ToolBarData