import React from "react";
import "./content.scss";
import { Container, Grid, GridCol, Stack, Text } from "@mantine/core";
import {
  IconArrowBigRightFilled,
  IconArrowWaveRightDown,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useDroppable } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { SortableItem } from "./sortable";
import QuestionContainer from "@components/questionContainer";

const Content: React.FC<any> = ({ questions }) => {
  const { setNodeRef, isOver } = useDroppable({ id: "dropZone" });
  return (
    <div className="mainContainer">
      <Container className="container">
        <Grid className="chooseText">
          <Grid.Col span={10} pt={10}>
            <Text className="text" draggable="true">
              Choose a template, or drag and drop a question to get started
            </Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <IconArrowWaveRightDown />
          </Grid.Col>
        </Grid>

        {/* <Grid >
                        <GridCol span={11}><Text>Drag a question from the list on the right</Text></GridCol>
                        <GridCol span={1}>
                            <IconArrowBigRightFilled />
                        </GridCol>
                    </Grid>
                    <Text>
                        or, you can
                        <Link to="/p/charming"> learn more about FourEyes here</Link>
                        :-)
                    </Text> */}
        <SortableContext
          items={questions.map((question: any) => question.id)}
          strategy={rectSortingStrategy}
        >
          <Stack className="midContainer" ref={setNodeRef}>
            {questions.map((question:any) => (
              <QuestionContainer {...question} />
            ))}
          </Stack>
        </SortableContext>
      </Container>
    </div>
  );
};

export default Content;
