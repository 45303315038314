import React, { useMemo, useState } from "react";
import { Button, Group, ThemeIcon } from "@mantine/core";
import ToolBar from "@components/toolbar";
import Content from "@components/content/Content";
import "./home.scss";
import { DndContext, DragOverEvent, DragOverlay, rectIntersection } from "@dnd-kit/core";
import { IDragableButton } from "@components/toolbar/toolbar.model";
import { arrayMove } from "@dnd-kit/sortable";

const Home = () => {
  const [activeItem, setActiveItem] = useState<null | IDragableButton | any>(
    null
  );
  const [questions, setQuestions] = useState<any>([])

  const Icon = useMemo(() => {
    return activeItem?.Icon;
  }, [activeItem]);

  const onDragOver = ({ active, over }: DragOverEvent) => {
    if (!over) {
      if (activeItem === null) return;
      const indx = questions.findIndex((x:any) => x.id === active.id);
      if (indx === -1) return;
      setQuestions(questions.filter((x:any) => x.id !== active.id));
      return;
    }

    const active_indx = questions.findIndex((x:any) => x.id === active.id);
    const over_indx = questions.findIndex((x:any) => x.id === over.id);

    if (active_indx !== -1 && over_indx !== -1) {
      if (active_indx === over_indx) return;
      setQuestions(arrayMove(questions, active_indx, over_indx));
    } else if (over.id === "dropZone") {
      if (questions.findIndex((x:any) => x.id === active.id) === -1) {
          setQuestions([
            ...questions,
            activeItem
          ]);
       
      }
    }
  };

  return (
    <Group justify="center" className="homeContainer">
      <DndContext
        onDragStart={({ active }) => setActiveItem(active.data.current)}
        onDragOver={onDragOver}
        onDragCancel={()=>setActiveItem(null)}
        onDragEnd={()=>setActiveItem(null)}
        collisionDetection={rectIntersection}
      >
        <Content questions = {questions} />
        <ToolBar questions = {questions} activeItem={activeItem} />
        <DragOverlay>
          {activeItem ? (
            <Button
              variant="filled"
              leftSection={
                <ThemeIcon variant="transparent" color="white">
                  <Icon />
                </ThemeIcon>
              }
            >
              {activeItem.title}
            </Button>
          ) : null}
        </DragOverlay>
      </DndContext>
    </Group>
  );
};

export default Home;
