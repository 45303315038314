// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/homeBackground.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeContainer {
  width: 100dvw;
  height: 100dvh;
  margin: 0;
  padding: 0;
  background: transparent url(${___CSS_LOADER_URL_REPLACEMENT_0___}) bottom center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 40px;
}

.overlay-button {
  background-color: black !important;
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACD,cAAA;EACA,SAAA;EACA,UAAA;EACA,uFAAA;EACA,sBAAA;EACA,4BAAA;EACA,iBAAA;AACH;;AAEA;EACI,kCAAA;EACA,uBAAA;AACJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
