import { Button, Group, Stack } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import "./toolbar.scss";
import { generateId } from "@helpers";
import DragableButton from "./dragableButton";
import ToolBarData from "./data";
import { IDragableItem } from "./toolbar.model";

const ToolBar: React.FC<any> = ({ questions, activeItem }) => {
  const genrerateDragbleData = () => {
    return ToolBarData.map((groupData) =>
      groupData.map((data) => ({ id: generateId(), data }))
    );
  };
  const [toolbarData, setToolbarData] = useState(genrerateDragbleData());

  const activeId = useRef("");

  useEffect(() => {
    if (activeItem) {
      activeId.current = activeItem.id;
    } else {
      questions.some((data: any) => data.id === activeId?.current) &&
        setToolbarData((toolbarData) =>
          toolbarData.map((groupData) =>
            groupData.map((data) =>
              data.id === activeId?.current
                ? { ...data, id: generateId(data?.id?.split("-")?.at(1)) }
                : data
            )
          )
        );
    }
  }, [activeItem]);

  return (
    <Stack gap={10} className="toolbar">
      {toolbarData.map((group: IDragableItem[]) => (
        <Group>
          {group.map(({ id, data }: IDragableItem) => (
            <DragableButton id={id} title={data.title} Icon={data.Icon} />
          ))}
        </Group>
      ))}
      <Button className="gallery">Templete Gallery</Button>
    </Stack>
  );
};

export default ToolBar;
