// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
  width: 275px;
}
.toolbar .mantine-Button-root {
  width: 135px !important;
  cursor: all-scroll;
  overflow: hidden;
  border-radius: 0;
  border: none;
  padding: 0;
  background-color: white;
  font-size: 13px;
  color: #444;
}
.toolbar .mantine-Button-root .mantine-Button-inner {
  justify-content: flex-start;
}
.toolbar .mantine-Button-root .mantine-Button-inner .mantine-Button-section {
  margin: 0;
}
.toolbar .mantine-Group-root {
  gap: 5px;
}
.toolbar .gallery {
  width: 100% !important;
  cursor: pointer;
  color: white;
  background-color: #2773b7;
  font-size: 14px;
  margin-top: 10px;
}
.toolbar .gallery .mantine-Button-inner {
  justify-content: center;
  border-color: #216199;
}
.toolbar .mantine-ThemeIcon-root path {
  stroke: #43aed8;
}`, "",{"version":3,"sources":["webpack://./src/components/toolbar/toolbar.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,UAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;AAER;AACQ;EACI,2BAAA;AACZ;AAAY;EACI,SAAA;AAEhB;AAEI;EACI,QAAA;AAAR;AAEI;EAEI,sBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;AADR;AAIQ;EACI,uBAAA;EACA,qBAAA;AAFZ;AAMI;EACI,eAAA;AAJR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
