import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Flex, Group, Stack, Text } from '@mantine/core'
import React from 'react'

const QuestionContainer:React.FC<any> = ({id, title, Icon}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
      } = useSortable({ id, data:{id, title, Icon} });

      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        width:"100%",
        border:"1px solid red"
      };
  return (
    <Flex ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Box></Box>
        <Stack>
            <Group>
                <Text>{title}</Text>
            </Group>
        </Stack>
    </Flex>
  )
}

export default QuestionContainer